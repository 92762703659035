import clsx from 'clsx'
import NextLink from 'next/link'
import React, { RefObject } from 'react'
import styles from '../app/styles/NavCart.module.sass'
import { czkMoneyFormatter } from '../utils/moneyFormatter'
import { prepareLineItemsForCheckout } from '../utils/prepereLineItemsForCheckout'
import { redirectToCheckout } from '../utils/redirectToCheckout'
import { ShoppingContext } from '../utils/ShoppingContext'
import { useShoppingCart } from '../utils/useShoppingCart'
import { Button } from './Button'
import { Cross } from './Cross'
import { CrossSmall } from './CrossSmall'
import { Image } from './Image'
import { Payments } from './Payments.svg'

export function NavCartInner() {
	const cart = useShoppingCart()
	const cartItems = cart.cartDetails
	const shoppingContext = React.useContext(ShoppingContext)
	const cartEmpty = cart.cartCount === 0
	const deliveryPrice = 150 //in czk

	const ref = React.useRef() as RefObject<HTMLDivElement>

	React.useEffect(() => {
		if (shoppingContext?.cartOpen) {
			const handleClickOutside = (event: MouseEvent) => {
				const clickedEl = event.target as Node | null
				if (ref.current && !ref.current.contains(clickedEl)) {
					shoppingContext.cartOpenFunction(false)
				}
			}
			document.addEventListener('mousedown', handleClickOutside)

			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}
	}, [ref, shoppingContext])

	return (
		<div className={clsx(styles.wrapper, shoppingContext?.cartOpen && styles.visible)}>
			<div className={styles.inner} ref={ref}>
				<button className={styles.close} onClick={() => shoppingContext?.cartOpenFunction(false)}>
					<Cross />
				</button>
				<h4 className={styles.headline}>Tvůj košík:</h4>
				<ul className={styles.items}>
					{cartItems &&
						Object.values(cartItems).map((item) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							const productLink = item.product_data?.link ? item.product_data?.link : null
							return (
								<li className={styles.item} key={item.id}>
									<div className={styles.itemImage}>
										{item.image && (
											<Image
												src={item.image}
												alt={'Product image'}
												layout="fill"
												objectFit="cover"
											/>
										)}
									</div>
									{productLink ? (
										<NextLink href={productLink}>
											<a className={styles.itemHeadline}>
												{item.name}
												<br></br>
												<span className={styles.itemPrice}>{czkMoneyFormatter(item.price)}</span>
											</a>
										</NextLink>
									) : (
										<a className={styles.itemHeadline}>
											{item.name}
											<br></br>
											<span className={styles.itemPrice}>{czkMoneyFormatter(item.price)}</span>
										</a>
									)}

									<span className={styles.itemQuantity}>{item.quantity}ks</span>
									<button
										aria-label="Odstranit z košíku"
										className={styles.itemRemove}
										onClick={() => cart.removeItem(item.id)}>
										<CrossSmall />
									</button>
								</li>
							)
						})}
				</ul>
				<div className={styles.actions}>
					{cartEmpty && <p className={styles.empty}>Košík je prázdný</p>}
					{cart.totalPrice !== undefined && !cartEmpty && (
						<p className={styles.total}>
							<span className={styles.delivery}>Doprava: {czkMoneyFormatter(deliveryPrice)}</span>
							<br></br>
							<b>Celkem: </b>
							{czkMoneyFormatter(cart.totalPrice + deliveryPrice)}
						</p>
					)}
					{/* <NextLink href="/kosik">
						<a className={styles.cartLink}>Přejít do košíku</a>
					</NextLink>
					<br></br> */}
					{!cartEmpty && (
						<>
							<Button
								className="viewTransaction"
								onClick={() => {
									if (cartItems) {
										redirectToCheckout(prepareLineItemsForCheckout(Object.values(cartItems)))
									}
								}}>
								Dokončit nákup
							</Button>
							<div className={styles.payments}>
								<Payments />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
