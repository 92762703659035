/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */

import { loadStripe, Stripe } from '@stripe/stripe-js'
import { ensureString } from './assertString'

let stripePromise: Promise<Stripe | null>
const getStripe = async () => {
	if (!stripePromise) {
		stripePromise = loadStripe(ensureString(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY))
	}
	const stripe = await stripePromise

	if (!stripe) {
		throw new Error('Cannot load Stripe')
	}

	return stripe
}

export default getStripe
