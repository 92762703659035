import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import type { LinkResult } from '../app/data/content/LinkFragment'
import styles from '../app/styles/Link.module.sass'

type LinkProps = {
	link: LinkResult
	isBtn?: boolean
	nonExternal?: boolean
	animation?: string
	suffix?: string
	className?: string
	spanWrap?: boolean
}

export function Link(props: LinkProps) {
	if (props.link?.externalLink || props.link?.internalLink) {
		const external = Boolean(props.link.type === 'external')
		const url = (
			props.link.internalLink?.url && !external
				? props.link.internalLink.url
				: props.link.externalLink
		) as string
		const anchorLink = url ? url.includes('#') : null
		if (url) {
			return (
				<NextLink href={url}>
					<a
						className={clsx(
							props.isBtn ? styles.btn : styles.link,
							anchorLink && styles.anchor,
							props.className && styles[props.className]
						)}
						data-aos={props.animation ? props.animation : ''}
						target={external && !props.nonExternal && !anchorLink ? '_blank' : ''}>
						{props.spanWrap ? (
							<span>{props.link.title + (props.suffix ? ' ' + props.suffix : '')}</span>
						) : (
							props.link.title + (props.suffix ? ' ' + props.suffix : '')
						)}
					</a>
				</NextLink>
			)
		}
		return null
	} else {
		return null
	}
}
