import React from 'react'

export function CrossSmall() {
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 36.52 37.22">
			<defs></defs>
			<title>cross-small</title>
			<line stroke="currentColor" x1="1.08" y1="36.53" x2="35.43" y2="0.69" />
			<line stroke="currentColor" x1="0.71" y1="1.06" x2="35.81" y2="36.16" />
		</svg>
	)
}
