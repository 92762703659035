import React from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'
import { Image } from './Image'

type ImageProps = React.ComponentProps<typeof Image>

export function ContemberImage(props: {
	layout?: ImageProps['layout']
	objectFit?: ImageProps['objectFit']
	onLoad?: ImageProps['onLoad']
	loading?: ImageProps['loading']
	sizes?: string
	image: ImageResult
	priority?: boolean
}) {
	const {
		image: { url, width, height },
	} = props
	if (!(url && width && height)) {
		return null
	}

	const baseProps = {
		sizes: props.sizes,
		loading: props.loading,
		objectFit: props.objectFit,
		onLoad: props.onLoad,
		unoptimized: process.env.NODE_ENV === 'development',
		priority: props.priority,
	}

	if (props.layout === 'fill') {
		return (
			<Image
				className="contember-image"
				{...baseProps}
				src={url}
				alt={''}
				title={''}
				layout={props.layout}
			/>
		)
	}
	return (
		<Image
			className="contember-image"
			{...baseProps}
			src={url}
			width={width}
			height={height}
			alt={''}
			title={''}
			layout={props.layout}
		/>
	)
}
