import React from 'react'
import styles from '../app/styles/Nav.module.sass'
import { ShoppingContext } from '../utils/ShoppingContext'
import { useShoppingCart } from '../utils/useShoppingCart'
import { Bag } from './Bag.svg'

export function NavCartButton() {
	const cart = useShoppingCart()
	const shoppingContext = React.useContext(ShoppingContext)
	return (
		<button className={styles.cart} onClick={() => shoppingContext?.cartOpenFunction(true)}>
			<Bag />
			<span className={styles.cartCount}>{cart.cartCount}</span>
		</button>
	)
}
