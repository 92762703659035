import clsx from 'clsx'
import React from 'react'
import type { FooterMenuItems } from '../app/data/content/FooterMenuFragment'
import type { MenuItems } from '../app/data/content/MenuFragment'
import styles from '../app/styles/Footer.module.sass'
import { Container } from './Container'
import { Link } from './Link'
import { Logo } from './Logo'

export function Footer(props: { items: MenuItems; footerItems: FooterMenuItems }) {
	return (
		<footer className={styles.wrapper}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.left}>
						<a href="/" className={styles.logo}>
							<Logo />
						</a>
						<br />
						<a href="tel:+420731473705">TEL: +420 731 473 705</a>
						<br />
						<a href="mailto:info@neonhort.cz">info@neonhort.cz</a>
						<br />
						<br />
						<p>
							NEON HORT s.r.o.<br></br>
							IČ: 21340005, DIČ: CZ21340005<br></br>
							Jungmannova 736/10, Praha, 110 00<br></br>
						</p>
						<p>
							<h4>Adresa provozovny:</h4>K Lochkovu 3/10, Praha, 154 00<br></br>
						</p>
					</div>
					<div className={styles.right}>
						<ul className={clsx(styles.menu)}>
							{props.items?.items.map((item) => (
								<li className={styles.menuItem} key={item.id}>
									{item.link && <Link link={item.link} />}
								</li>
							))}
						</ul>
						<ul className={clsx(styles.menu)}>
							{props.footerItems?.items.map((item) => (
								<li className={styles.menuItem} key={item.id}>
									{item.link && <Link link={item.link} />}
								</li>
							))}
						</ul>
					</div>
				</div>
			</Container>
		</footer>
	)
}
