import React from 'react'

export function Bag() {
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 29.11 30.4">
			<title>bag</title>
			<g id="pages">
				<g id="_00-home-mobile-lista-dole2" data-name="00-home-mobile-lista-dole2">
					<g id="icon-cart">
						<path
							stroke="currentColor"
							strokeWidth="2px"
							fill="none"
							id="Rectangle"
							d="M3.25,9.4H26.35a2,2,0,0,1,2,2.43l-3.56,16a2,2,0,0,1-2,1.57H6.8a2,2,0,0,1-2-1.57l-3.56-16A2,2,0,0,1,3.25,9.4Z"
							transform="translate(-0.25 0)"
						/>
						<path
							stroke="currentColor"
							strokeWidth="2px"
							fill="none"
							id="Oval"
							d="M21.2,13V7.4a6.4,6.4,0,0,0-12.8,0V13"
							transform="translate(-0.25 0)"
						/>
					</g>
				</g>
			</g>
		</svg>
	)
}
