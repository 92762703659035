import clsx from 'clsx'
import { default as NextLink } from 'next/link'
import React from 'react'
import type { MenuItems } from '../app/data/content/MenuFragment'
import styles from '../app/styles/Nav.module.sass'
import { IfHydrated } from '../packages/@necktip/next/hydratation/index'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Cross } from './Cross'
import { Link } from './Link'
import { Logo } from './Logo'
import { Menu } from './Menu'
import { NavCartButton } from './NavCartButton'

export function Nav(props: { items: MenuItems; className?: string }) {
	const [submenuOpen, setSubmenuOpen] = React.useState(false)
	const [menuOpened, setMenuOpened] = React.useState(false)
	const toggle: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
		setMenuOpened((old) => !old)
	}, [])
	const [loading] = usePageLoadingState()
	React.useEffect(() => {
		setMenuOpened(false)
	}, [loading])
	return (
		<nav
			className={clsx(
				styles.wrapper,
				props.className && styles[props.className],
				menuOpened && styles.menuOpened,
				submenuOpen && styles.filledColor
			)}>
			<Container>
				<div className={styles.inner}>
					<NextLink href="/">
						<a className={styles.logo}>
							<Logo />
						</a>
					</NextLink>
					<IfHydrated yes={() => <NavCartButton />} />
					<ul className={clsx(styles.menu)}>
						{props.items?.items.map((item, index) => {
							const isLast = index + 1 === props.items?.items.length
							const hasSubItems = item.subItems.length > 0
							return (
								<li
									className={clsx(styles.menuItem, hasSubItems && styles.hasSubItems)}
									onMouseEnter={() => {
										if (hasSubItems) {
											setSubmenuOpen(true)
										}
									}}
									onMouseLeave={() => {
										setSubmenuOpen(false)
									}}
									key={item.id}>
									{item.link && (
										<Link
											isBtn={isLast}
											link={item.link}
											className={isLast ? 'invertedMobile' : undefined}
											nonExternal
										/>
									)}
									{hasSubItems && (
										<div className={styles.menuItemSub}>
											<ul>
												{item.subItems.map((subitem) => (
													<li className={styles.menuItem} key={subitem.id}>
														{subitem.link?.internalLink?.page?.seo?.ogImage && (
															<div className={styles.menuItemImage}>
																<ContemberImage
																	image={subitem.link?.internalLink?.page?.seo?.ogImage}
																	objectFit="cover"
																	layout="fill"
																/>
															</div>
														)}
														<article>
															{subitem.link && <Link link={subitem.link} nonExternal />}
															<p>{subitem.link?.internalLink?.page?.seo?.description}</p>
														</article>
													</li>
												))}
											</ul>
										</div>
									)}
								</li>
							)
						})}
					</ul>
					{!menuOpened && (
						<button className={styles.open} onClick={toggle}>
							<Menu />
						</button>
					)}
					{menuOpened && (
						<button className={styles.close} onClick={toggle}>
							<Cross />
						</button>
					)}
				</div>
			</Container>
		</nav>
	)
}
