import React from 'react'

export function Payments() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 1037.05 135.5">
			<defs>
				<clipPath id="clip-path" transform="translate(0 1.6)">
					<path fill="none" d="M0 0H212.01V133.9H0z"></path>
				</clipPath>
				<clipPath id="clip-path-2" transform="translate(0 1.6)">
					<path fill="none" d="M261.37 -0.53H473.38V133.37H261.37z"></path>
				</clipPath>
				<clipPath id="clip-path-3" transform="translate(0 1.6)">
					<path fill="none" d="M522.91 -0.71H734.92V133.19H522.91z"></path>
				</clipPath>
				<clipPath id="clip-path-4" transform="translate(0 1.6)">
					<path
						fill="none"
						d="M888.25 62.4h-28.1v11.7h16.2c-1.5 7.4-7.8 11.7-16.2 11.7a17.8 17.8 0 110-35.6 17.12 17.12 0 0111.1 4l8.8-8.8a29.72 29.72 0 00-19.9-7.5 30.2 30.2 0 100 60.4c15.1 0 28.8-11 28.8-30.2a26.07 26.07 0 00-.7-5.7z"></path>
				</clipPath>
			</defs>
			<g clipPath="url(#clip-path)">
				<path
					d="M195.28 0H16.74A16.44 16.44 0 000 16.74v100.42a16.78 16.78 0 0016.74 16.74h178.54A16.43 16.43 0 00212 117.16V16.74A16.78 16.78 0 00195.28 0z"
					transform="translate(0 1.6)"
					style={{ isolation: 'isolate' }}
					opacity="0.07"></path>
				<path
					fill="#fff"
					d="M195.28 5.58a11.19 11.19 0 0111.15 11.16v100.42a11.19 11.19 0 01-11.15 11.16H16.74a11.19 11.19 0 01-11.16-11.16V16.74A11.19 11.19 0 0116.74 5.58z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#2c749c"
					d="M157.89 56.35h-1.67c-2.23 5.58-3.91 8.37-5.58 16.74h10.6c-1.67-8.37-1.67-12.28-3.35-16.74zm16.18 32.92h-9.48c-.56 0-.56 0-1.12-.56l-1.11-5-.56-1.12h-13.39c-.56 0-1.12 0-1.12 1.12l-1.67 5a.55.55 0 01-.56.56h-11.71l1.11-2.79 16.18-37.94c0-2.79 1.67-3.91 4.46-3.91h8.37c.56 0 1.12 0 1.12 1.12L172.4 82a23.6 23.6 0 011.12 6.13c.55.58.55.58.55 1.14zm-74.76-1.68l2.23-10a2 2 0 011.12.56c3.9 1.67 7.81 2.79 11.72 2.23a10.45 10.45 0 003.9-1.11c2.79-1.12 2.79-3.91.56-6.14-1.12-1.12-2.79-1.68-4.46-2.79a22.83 22.83 0 01-6.14-3.91c-6.7-5.58-4.47-13.39-.56-17.29 3.35-2.23 5-4.47 9.49-4.47 6.69 0 13.94 0 17.29 1.12h.54a58.89 58.89 0 01-2.23 9.48C130 54.12 127.21 53 124.42 53a15.35 15.35 0 00-5 .56 2.87 2.87 0 00-2.23 1.12 2.68 2.68 0 000 3.9l2.81 2.23a61.59 61.59 0 016.14 3.35c2.79 1.68 5.58 4.46 6.14 7.81 1.12 5-.56 9.49-5 12.83-2.79 2.24-3.91 3.35-7.81 3.35-7.81 0-14 .56-19-1.11-.6 1.11-.6 1.11-1.16.55zm-19.53 1.68c.56-3.91.56-3.91 1.12-5.58 2.79-12.28 5.58-25.11 7.81-37.38.56-1.12.56-1.68 1.67-1.68h10.05a167.17 167.17 0 01-3.91 17.86c-1.67 8.37-3.35 16.74-5.58 25.1 0 1.12-.56 1.12-1.67 1.12zM27.9 45.75c0-.56 1.11-1.12 1.67-1.12h19a5.44 5.44 0 015.58 4.47l5 24.55c0 .55 0 .55.56 1.11a.55.55 0 01.56-.56L72 45.75c-.56-.56 0-1.12.56-1.12h11.69c0 .56 0 .56-.56 1.12l-17.3 40.73c-.55 1.11-.55 1.67-1.11 2.23s-1.68 0-2.79 0h-8.37c-.56 0-1.12 0-1.12-1.12L44.08 53a11.35 11.35 0 00-5-3.34 37 37 0 00-10.61-2.79z"
					transform="translate(0 1.6)"></path>
			</g>
			<g clipPath="url(#clip-path-2)">
				<path
					d="M456.64-.53H278.1a16.43 16.43 0 00-16.73 16.73v100.43a16.79 16.79 0 0016.73 16.74h178.54a16.45 16.45 0 0016.74-16.74V16.2A16.79 16.79 0 00456.64-.53z"
					transform="translate(0 1.6)"
					style={{ isolation: 'isolate' }}
					opacity="0.07"></path>
				<path
					fill="#fff"
					d="M456.64 5.05A11.19 11.19 0 01467.8 16.2v100.43a11.2 11.2 0 01-11.16 11.16H278.1a11.19 11.19 0 01-11.1-11.16V16.2a11.19 11.19 0 0111.1-11.15z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#eb001b"
					d="M345.05 105.47a39.06 39.06 0 10-39.05-39 39.05 39.05 0 0039.05 39z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#f79e1b"
					d="M389.69 105.47a39.06 39.06 0 10-39.06-39 39 39 0 0039.06 39z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#ff5f00"
					d="M384.11 66.42a38.12 38.12 0 00-16.74-31.81c-10 7.26-16.74 19-16.74 31.81a38.86 38.86 0 0016.74 31.8 38.1 38.1 0 0016.74-31.8z"
					transform="translate(0 1.6)"></path>
			</g>
			<g clipPath="url(#clip-path-3)">
				<path
					d="M714.76-.71H539.11a25.86 25.86 0 00-3.93.35A13 13 0 00531.44.9a12.55 12.55 0 00-5.5 5.5 13.15 13.15 0 00-1.23 3.74 27.2 27.2 0 00-.35 3.93v104.34a27.33 27.33 0 00.35 3.93 13.36 13.36 0 001.23 3.74 12.46 12.46 0 005.5 5.5 12.94 12.94 0 003.74 1.23 27.2 27.2 0 003.93.35h179.62a27.46 27.46 0 003.94-.35 13.19 13.19 0 003.73-1.23 12.6 12.6 0 005.5-5.5 13 13 0 001.23-3.74 26 26 0 00.35-3.93v-1.81V18v-2.15-1.8a25.86 25.86 0 00-.35-3.93 12.84 12.84 0 00-1.23-3.74 12.55 12.55 0 00-5.5-5.5 13.19 13.19 0 00-3.73-1.23 26.1 26.1 0 00-3.94-.35h-1.81z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#fff"
					d="M714.75 3.75h3.83a22.11 22.11 0 013.27.28 8.64 8.64 0 012.5.82 8.1 8.1 0 013.55 3.54 8.91 8.91 0 01.81 2.51 21.86 21.86 0 01.28 3.26v104.1a22.22 22.22 0 01-.28 3.27 8.82 8.82 0 01-.81 2.5 8.1 8.1 0 01-3.55 3.54 8.68 8.68 0 01-2.49.82 23.41 23.41 0 01-3.26.28H539.23a23 23 0 01-3.27-.28 8.9 8.9 0 01-2.51-.82 8.16 8.16 0 01-2.05-1.49 8.34 8.34 0 01-1.49-2 8.88 8.88 0 01-.81-2.51 20.81 20.81 0 01-.28-3.26V15.9v-1.71a21 21 0 01.28-3.27 8.72 8.72 0 01.81-2.5 8.3 8.3 0 013.55-3.55 8.82 8.82 0 012.5-.81 22.11 22.11 0 013.27-.28h175.52z"
					transform="translate(0 1.6)"></path>
				<path
					d="M579.3 44.49a11.53 11.53 0 002.7-8.24 11.45 11.45 0 00-7.59 3.93 10.82 10.82 0 00-2.73 7.92c2.88.25 5.79-1.45 7.62-3.61zM581.94 48.67c-4.24-.25-7.83 2.4-9.86 2.4s-5.11-2.27-8.46-2.21A12.49 12.49 0 00553 55.3c-4.54 7.84-1.2 19.46 3.23 25.85 2.14 3.15 4.73 6.63 8.14 6.5 3.22-.12 4.48-2.08 8.4-2.08s5.05 2.08 8.46 2 5.75-3.16 7.89-6.32a28.19 28.19 0 003.55-7.25 11.48 11.48 0 01-6.88-10.43c-.07-6.51 5.3-9.6 5.55-9.79a12.08 12.08 0 00-9.41-5.12M624.12 39.87c9.2 0 15.6 6.34 15.6 15.56s-6.53 15.64-15.83 15.64h-10.18v16.19h-7.36V39.87zm-10.41 25h8.44c6.41 0 10.05-3.44 10.05-9.42s-3.64-9.4-10-9.4h-8.47zM641.55 77.44c0-6.07 4.63-9.56 13.17-10.08l9.16-.56v-2.63c0-3.84-2.52-5.94-7-5.94-3.72 0-6.41 1.9-7 4.82h-6.63c.2-6.14 6-10.6 13.79-10.6 8.41 0 13.9 4.4 13.9 11.23v23.58h-6.8v-5.68H664c-1.93 3.71-6.2 6-10.84 6-6.85.04-11.61-4.03-11.61-10.14zm22.33-3v-2.71l-8.17.52c-4.6.3-7 2-7 5s2.5 4.77 6.4 4.77c5-.02 8.77-3.2 8.77-7.63zM677.2 99.94v-5.68c.46.06 1.58.13 2.17.13 3.25 0 5.09-1.38 6.21-4.93l.65-2.1-12.45-34.49h7.69l8.67 28h.16l8.68-28h7.49L693.56 89.1c-3 8.31-6.34 11-13.5 11a20.38 20.38 0 01-2.86-.16z"
					transform="translate(0 1.6)"></path>
			</g>
			<g>
				<path
					d="M790.15 133.9h242.6a4.33 4.33 0 004.3-4.3V2.7a4.33 4.33 0 00-4.3-4.3h-242.6a4.27 4.27 0 00-4.3 4.24V129.7a4.25 4.25 0 004.3 4.2z"
					transform="translate(0 1.6)"
					style={{ isolation: 'isolate' }}
					opacity="0.5"></path>
				<path
					d="M790.35 0h242.5a2.8 2.8 0 012.8 2.8v126.7a2.8 2.8 0 01-2.8 2.8h-242.5a2.8 2.8 0 01-2.8-2.8V2.8a2.8 2.8 0 012.8-2.8z"
					transform="translate(0 1.6)"></path>
				<path
					fill="#fff"
					d="M1032.45 130.8h-241.9a1.37 1.37 0 01-1.4-1.34V3a1.37 1.37 0 011.34-1.4h242a1.37 1.37 0 011.4 1.34V129.3a1.4 1.4 0 01-1.28 1.5z"
					transform="translate(0 1.6)"></path>
			</g>
			<g>
				<g>
					<g clipPath="url(#clip-path-4)">
						<path
							fill="#fbbc05"
							d="M827.25 85.8V50.1l23.3 17.8z"
							transform="translate(0 1.6)"></path>
					</g>
					<g clipPath="url(#clip-path-4)">
						<path
							fill="#ea4335"
							d="M827.25 50.1l23.3 17.8 9.6-8.4 32.9-5.3V35h-65.8z"
							transform="translate(0 1.6)"></path>
					</g>
					<g clipPath="url(#clip-path-4)">
						<path
							fill="#34a853"
							d="M827.25 85.8l41.1-31.5 10.8 1.4L893 35.1v65.8h-65.7z"
							transform="translate(0 1.6)"></path>
					</g>
					<g clipPath="url(#clip-path-4)">
						<path
							fill="#4285f4"
							d="M893.05 100.8l-42.5-32.9-5.5-4.1 48-13.7z"
							transform="translate(0 1.6)"></path>
					</g>
				</g>
				<g fill="#757575">
					<path
						d="M922.65 71.8v12.5h-5.5V51.4h12.9c3.6 0 6.5.9 8.5 2.8a9.37 9.37 0 013.1 7.4 9.26 9.26 0 01-3.1 7.4c-2.1 1.9-4.9 2.8-8.5 2.8zm0-4.4h7.4a6 6 0 004.6-1.6 6 6 0 001.5-4.1 5.54 5.54 0 00-5.15-5.92 6.44 6.44 0 00-1 0h-7.4zM961 84.3a12.65 12.65 0 01-.5-1.8l-.3-1.8a10.49 10.49 0 01-3 2.9 7.3 7.3 0 01-4.1 1.2 8.42 8.42 0 01-5.9-2 7 7 0 01-2-5.4 6.19 6.19 0 012.8-5.5 13.71 13.71 0 017.9-2h4.3v-2.2a4.1 4.1 0 00-1.1-3 5.32 5.32 0 00-6.2-.2 3 3 0 00-1.1 2.3h-5.3v-.1a6.15 6.15 0 012.6-5.1 10.83 10.83 0 017.2-2.2 10 10 0 016.9 2.2c1.8 1.4 2.6 3.5 2.6 6.2v10.9a25.53 25.53 0 00.2 2.9 13.24 13.24 0 00.7 2.7zm-6.8-3.9a6.63 6.63 0 003.7-1 5.55 5.55 0 002.2-2.4v-3.6h-4.4a5.6 5.6 0 00-3.8 1.2 3.62 3.62 0 00-1.4 2.8 2.81 2.81 0 00.9 2.2 5.12 5.12 0 002.75.8zM979.15 74.5l.5 2.4h.1l5.4-17.1h6L981.05 88a13.14 13.14 0 01-2.7 4.4 6.52 6.52 0 01-5 1.8 8.34 8.34 0 01-1.5-.1l-1.5-.3.6-4.3a2.41 2.41 0 01.8.1 2.15 2.15 0 00.8 0 3 3 0 002.4-1 9.78 9.78 0 001.5-2.4l.9-2.2-8.9-24.2h6z"
						transform="translate(0 1.6)"></path>
				</g>
			</g>
		</svg>
	)
}
