import type { CartEntry } from 'use-shopping-cart'
import { formatAmountForStripe } from './stripe-helpers'

export function prepareLineItemsForCheckout(items: Array<CartEntry>) {
	const lineItems = items.map((item) => ({
		name: item.name,
		amount: formatAmountForStripe(item.price, item.currency),
		currency: item.currency,
		quantity: item.quantity,
		images: item.image ? [item.image] : undefined,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		contember_id: item.product_data.contember_id ? item.product_data.contember_id : undefined,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		submitted_configurator_id: item.product_data.submitted_configurator_id
			? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
			  //@ts-ignore
			  item.product_data.submitted_configurator_id
			: undefined,
	}))

	return lineItems
}

export type LineItems = ReturnType<typeof prepareLineItemsForCheckout>
export type LineItemsStripped = Omit<LineItems[number], 'contember_id'>[]
