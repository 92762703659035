import React from 'react'

export function Menu() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="53" height="44" viewBox="0 0 53 44">
			<g>
				<g>
					<path
						fill="currentColor"
						d="M53 9H0V.728C7.08 2.138 16.57 3 27 3c9.946 0 19.038-.784 26-2.079z"
					/>
				</g>
				<g transform="rotate(180 26.5 39.5)">
					<path
						fill="currentColor"
						d="M0 42.543h53V34.27c-7.08 1.41-16.57 2.273-27 2.273-9.946 0-19.038-.784-26-2.08z"
					/>
				</g>
				<g>
					<path fill="currentColor" d="M0 19h53v6H0z" />
				</g>
			</g>
		</svg>
	)
}
